import { render, staticRenderFns } from "./FileLinksTab.vue?vue&type=template&id=0abb6d1e&scoped=true"
import script from "./FileLinksTab.vue?vue&type=script&lang=js"
export * from "./FileLinksTab.vue?vue&type=script&lang=js"
import style0 from "./FileLinksTab.vue?vue&type=style&index=0&id=0abb6d1e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0abb6d1e",
  null
  
)

export default component.exports