<template>
  <base-tab-container :tabs-content="tabsContent">
    <base-tab
      v-for="tab in tabsContent"
      :key="tab.id"
      :title="tab.id"
      :is-selected="tab.active"
    >
      <filter-section
        :options="$options.options[tab.id]"
        :link-type="$options.linkType[tab.id]"
      />
      <keep-alive>
        <component
          :is="tab.id"
          :link-type="$options.linkType[tab.id]"
        />
      </keep-alive>
    </base-tab>
  </base-tab-container>
</template>

<script>
import BaseTabContainer from '@/components/ui/BaseTabContainer';
import BaseTab from '@/components/ui/BaseTab';
import VisitedLinksTab from '@/components/job/table/tabs/VisitedLinksTab';
import FileLinksTab from '@/components/job/table/tabs/FileLinksTab';
import PendingLinksTab from '@/components/job/table/tabs/PendingLinksTab';
import ErrorLinksTab from '@/components/job/table/tabs/ErrorLinksTab';
import { OPTIONS_FILTER, LINK_TYPE } from '@/constants/jobs.constant';
import FilterSection from '@/components/job/filters/FilterSection';

export default {
  name: 'JobTabs',
  components: {
    BaseTabContainer,
    BaseTab,
    VisitedLinksTab,
    FileLinksTab,
    PendingLinksTab,
    ErrorLinksTab,
    FilterSection,
  },
  data () {
    return {
      tabsContent: [
        {
          text: 'Visited Links',
          id: 'VisitedLinksTab',
          active: true,
        },
        {
          text: 'File Links',
          id: 'FileLinksTab',
          active: false,
        },
        {
          text: 'Pending Links',
          id: 'PendingLinksTab',
          active: false,
        },
        {
          text: 'Error Links',
          id: 'ErrorLinksTab',
          active: false,
        }
      ],
    };
  },
  options: OPTIONS_FILTER,
  linkType: LINK_TYPE,
};
</script>
