<template>
  <div>
    <mds-tabs
      :content="tabsContent"
      class="panel-tabs"
      @mds-tabs-item-active.prevent="setActiveItem"
    />
    <slot />
  </div>
</template>

<script>
import MdsTabs from '@mds/tabs';

export default {
  name: 'BaseTabContainer',
  components: {
    MdsTabs,
  },
  props: {
    tabsContent: {
      type: Array,
      required: true,
    },
  },
  data () {
    return {
      tabs: this.$children,
    };
  },
  methods: {
    /**
     * Switches between tabs by comparing the clicked element's id with the tab's title prop.
     * Ids in tabsContent MUST be the same as the title props passed to the BaseTab components.
     * @param {Object} event - Clicked tab's event.
     * @param {Object} event.currentTarget - Clicked tab.
     * @param {string} event.currentTarget.id - Clicked tab's id.
     */
    setActiveItem ({ currentTarget: { id } }) {
      this.tabsContent.forEach((tabContent) => {
        const tab = this.tabs.find((tab) => tab.title === tabContent.id);
        tab.isActive = (tab.title === id);
        tabContent.active = (tabContent.id === id);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.panel-tabs {
  margin-bottom: $mds-space-7-x;
}
</style>
