<template>
  <div v-if="isActive">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'BaseTabContent',
  props: {
    /** this prop MUST match the tab's id */
    title: {
      type: String,
      required: true,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      isActive: this.isSelected,
    };
  },
};
</script>
