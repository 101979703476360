<template>
  <mds-layout-grid>
    <job-tabs />
  </mds-layout-grid>
</template>

<script>
import JobTabs from '@/components/job/table/JobTabs';

export default {
  name: 'ProviderPage',
  components: {
    JobTabs,
  },
};
</script>
