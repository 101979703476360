<template>
  <store-provider
    v-bind="$options.storeInfo"
    :params="params"
    is-paged
  >
    <template v-slot="{ state: links }">
      <mds-row>
        <mds-col :push="10">
          <h4 class="file_link_tab__totalelements">
            Total Elements: {{ links('totalElements') }}
          </h4>
        </mds-col>
      </mds-row>
      <mds-table
        row-hover
        show-sortable
      >
        <mds-thead>
          <mds-th
            v-for="header in $options.headers"
            :key="header.fieldName"
            :style="header.style"
            :sortable="header.sortable"
            :sorted="isSorted(header.fieldName)"
            @mds-th-sort-changed="handleSortEvent(header.fieldName, $event)"
          >
            {{ header.text }}
          </mds-th>
        </mds-thead>
        <mds-tbody>
          <mds-tr
            v-for="link in links('job')"
            :key="link.id"
          >
            <mds-td
              v-for="header in $options.headers"
              :key="header.fieldName"
              :style="header.style"
            >
              <template v-if="header.fieldName === 'parentLink'">
                <a
                  :href="link.parentLink"
                  target="_blank"
                >
                  {{ link.parentLink | decodeLink }}
                </a>
              </template>
              <template
                v-else-if="
                  header.fieldName === 'linkText' && link[header.fieldName]
                "
              >
                <a
                  :href="`${link.parentLink}#:~:text=${link.linkText}`"
                  target="_blank"
                  v-text="link.linkText"
                />
              </template>
              <template
                v-else-if="
                  header.fieldName === 'uploadDate' && link[header.fieldName]
                "
              >
                {{ link[header.fieldName] | formatDate }}
              </template>
              <template v-else-if="header.fieldName === 'headers'">
                <mds-list-group>
                  <mds-list-group-item
                    v-for="(title, index) in JSON.parse(link.headers)"
                    :key="index"
                    :text="title"
                    :href="`${link.parentLink}#:~:text=${title}`"
                    target="_blank"
                  />
                </mds-list-group>
              </template>
              <template v-else-if="header.fieldName === 'actions'">
                <mds-button-container>
                  <mds-button
                    variation="icon-only"
                    icon="share"
                    type="button"
                    text="Open File"
                    target="_blank"
                    :href="link.fileLink"
                  />
                </mds-button-container>
              </template>
              <template v-else>
                {{ link[header.fieldName] || "—" }}
              </template>
            </mds-td>
          </mds-tr>
        </mds-tbody>
      </mds-table>
    </template>
  </store-provider>
</template>

<script>
import {
  MdsTable,
  MdsThead,
  MdsTh,
  MdsTbody,
  MdsTr,
  MdsTd
} from '@mds/data-table';
import { FILE_LINKS_HEADERS } from '@/constants/jobs.constant';
import { MdsButton, MdsButtonContainer } from '@mds/button';
import { MdsListGroup, MdsListGroupItem } from '@mds/list-group';
import StoreProvider from '@/components/common/StoreProvider';
import { formatDate } from '@/utils/global.util';
import { parseSorted, parseSortParams } from '@/utils/sort.util';
import { MODULE, COMMON } from '@/constants/store.constant';

export default {
  name: 'FileLinksTabs',
  components: {
    MdsTable,
    MdsThead,
    MdsTh,
    MdsTbody,
    MdsTr,
    MdsTd,
    MdsListGroup,
    MdsListGroupItem,
    MdsButton,
    MdsButtonContainer,
    StoreProvider,
  },
  filters: {
    formatDate,
    decodeLink (url) {
      return decodeURI(url);
    },
  },
  props: {
    linkType: {
      type: String,
      required: true,
    },
  },
  computed: {
    params () {
      return { ...this.$route.params, type: this.linkType };
    },
  },
  methods: {
    async handleSortEvent (fieldName) {
      const value = parseSortParams(this.$store.state[MODULE.JOB], fieldName);
      this.$store.commit(`${MODULE.JOB}/${COMMON.UPDATE_PARAM}`, {
        name: 'sort',
        value,
      });
      await this.$store.dispatch(`${MODULE.JOB}/${COMMON.FETCH_DATA}`, {
        params: this.params,
        shouldReset: true,
      });
    },
    isSorted (fieldName) {
      return parseSorted(this.$store.state[MODULE.JOB], fieldName);
    },
  },
  headers: FILE_LINKS_HEADERS,
  storeInfo: {
    module: MODULE.JOB,
    action: COMMON.FETCH_DATA,
    getter: COMMON.GET_STATE,
  },
};
</script>

<style lang="scss" scoped>
@import '@mds/typography';

.file_link_tab {
  &__totalelements {
    @include mds-eyebrow-heading();
  }
}
</style>
