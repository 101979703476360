<template>
  <mds-row class="filter">
    <mds-col :cols="7">
      <validation-observer
        v-slot="{ handleSubmit, invalid }"
        slim
      >
        <mds-form @submit.native.prevent="handleSubmit(getLinks)">
          <mds-fieldset
            legend="Filter by Column"
            hidden-legend
            horizontal
          >
            <validation-provider
              v-slot="{ errors, failed }"
              name="Column"
              rules="required"
              slim
            >
              <mds-select
                v-model="searchObject.name"
                :error="failed"
                :error-text="errors"
                placeholder="Choose a Column to Filter"
                label="Column"
                :options="options"
                @change.native="clearParams"
              />
            </validation-provider>
            <mds-fieldset
              v-if="searchObject.name.includes('Date')"
              legend="Date Range"
              hidden-legend
              horizontal
            >
              <validation-provider
                v-slot="{ errors, failed }"
                name="From Date"
                rules="required"
                slim
              >
                <mds-date-picker
                  v-model="dateRange.start"
                  :min-max-dates="minMaxDates"
                  :error="failed"
                  :error-text="errors"
                  label="From"
                  class="filter__date"
                  @input="setParam($event, `${searchObject.name}Start`)"
                />
              </validation-provider>
              <validation-provider
                v-slot="{ errors, failed }"
                name="To Date"
                rules="required"
                slim
              >
                <mds-date-picker
                  v-model="dateRange.end"
                  :min-max-dates="minMaxDates"
                  :error="failed"
                  :error-text="errors"
                  label="To"
                  class="filter__date"
                  @input="setParam($event, `${searchObject.name}End`)"
                />
              </validation-provider>
            </mds-fieldset>
            <validation-provider
              v-else
              v-slot="{ errors, failed }"
              name="Value"
              :rules="$options.rules[searchObject.name] || 'required'"
              slim
            >
              <mds-input
                v-model="searchObject.value"
                label="Value"
                :error="failed"
                :error-text="errors"
                placeholder="Search value"
                class="filter__search"
                @input="setParam($event, searchObject.name)"
              />
            </validation-provider>
            <mds-button
              variation="secondary"
              text="Clear"
              class="filter__button"
              @click="clearParams"
            />
            <mds-button
              variation="primary"
              :disabled="invalid"
              type="submit"
              text="Search"
              class="filter__button"
            />
          </mds-fieldset>
        </mds-form>
      </validation-observer>
    </mds-col>
  </mds-row>
</template>

<script>
import MdsInput from '@mds/input';
import MdsSelect from '@mds/select';
import MdsFieldset from '@mds/fieldset';
import { MdsButton } from '@mds/button';
import MdsDatePicker from '@mds/date-picker';
import MdsForm from '@mds/form';
import { MODULE, COMMON } from '@/constants/store.constant';
import { FILTER_RULES } from '@/constants/jobs.constant';

export default {
  name: 'FilterSection',
  components: {
    MdsInput,
    MdsSelect,
    MdsFieldset,
    MdsDatePicker,
    MdsButton,
    MdsForm,
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    linkType: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      searchObject: {
        name: '',
        value: '',
      },
      dateRange: {
        start: '',
        end: '',
      },
      minMaxDates: {
        min: new Date(2015, 0, 1),
        max: new Date(),
      },
    };
  },
  computed: {
    params () {
      return { ...this.$route.params, type: this.linkType };
    },
  },
  methods: {
    setParam (value, name) {
      this.$store.commit(`${MODULE.JOB}/${COMMON.UPDATE_PARAM}`, {
        name,
        value,
      });
    },
    clearParams () {
      this.$store.commit(`${MODULE.JOB}/${COMMON.UPDATE_STATE}`, {
        name: 'params',
        value: {
          page: 0,
          size: 40,
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      });
      this.searchObject.value = '';
      this.dateRange = {
        start: '',
        end: '',
      };
      this.getLinks();
    },
    async getLinks () {
      await this.$store.dispatch(`${MODULE.JOB}/${COMMON.FETCH_DATA}`, {
        params: this.params,
        shouldReset: true,
      });
    },
  },
  rules: FILTER_RULES,
};
</script>

<style lang="scss" scoped>
.filter {
  &__button {
    margin: 28px 5px 0;
    display: block;
    max-width: 90px;
  }
  &__date {
    margin-right: 10px;
  }
}
</style>
